@tailwind base;
@tailwind components;
@tailwind utilities;
.ant-wave {
  /* display: none !important; */
  /* box-shadow: 5px 5px 5px red !important; */
}
.ant-timeline-item-last {
  padding-bottom: 0 !important;
}
.ant-timeline-item-content {
  min-height: 0 !important;
}
.mainH {
  min-height: calc(100vh - 62px) !important;
  background-size: 100% calc(100vh - 62px) !important;
}
